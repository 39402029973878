.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.fs-10 {
    font-size: 10px;
}

.fs-11 {
    font-size: 11px;
}

.fs-12 {
    font-size: 12px;
    font-weight: 500;
}

.fs-14 {
    font-size: 14px;
}

.fs-15 {
    font-size: 15px;
}

.fs-16 {
    font-size: 16px;
}

.fs-18 {
    font-size: 18px;
}

.fs-20 {
    font-size: 20px;
}

.fs-22 {
    font-size: 22px;
}

.fs-23 {
    font-size: 23px;
}

.fs-24 {
    font-size: 24px;
}

.fs-25 {
    font-size: 25px;
}

.fs-28 {
    font-size: 28px;
    line-height: 40px;
}

.fs-30 {
    font-size: 30px;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700 !important;
}

.fw-800 {
    font-weight: 800 !important;
}

.gap-0 {
    gap: 0 !important;
}

.gap-05 {
    gap: 0.15rem !important;
}

.gap-1 {
    gap: 0.25rem !important;
}

.gap-2 {
    gap: 0.5rem !important;
}

.gap-3 {
    gap: 1rem !important;
}

.gap-4 {
    gap: 1.5rem !important;
}

.gap-5 {
    gap: 3rem !important;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.stream {
    position: absolute;
    left: 0;
    right: 0;
    top: 0px;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.avtaar-user,
.avtaar-user img {
    height: 100px;
    width: 100px;
    object-fit: cover !important;
}

.avatarImg img {
    object-fit: contain;
    border: 1px solid #414141;
    border-radius: 50%;
}

.selected .avatarImg img {
    object-fit: contain;
    border: 2px solid #16c988;
    border-radius: 50%;
}

.img-width img {
    width: 80px;
    height: 80px;
}

.cursor-pointer {
    cursor: pointer;
}

*::-webkit-scrollbar {
    width: 2px;
    height: 5px;
}

/* Track */
*::-webkit-scrollbar-track {
    background: #fff;
}

*::-webkit-scrollbar-thumb {
    background: #000;
}

.avatarSelected {
    border: 5px solid #16c988;
}

.selected-border {
    border: 1px solid red;
}

.selected-avtaar {
    width: 116px !important;
    height: 100%;
}

.ownedAvatar {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    background: #00000075;
    color: white;
}

.item-box {
    position: relative;
}
